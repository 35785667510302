<template>
  <div class="blog-wrapper">
    <!-- content -->

  <h3 class="rule-title">서울대학교 보건대학원 AMPFRI 총동문회 회칙</h3>
  <div class="step">
    <h4>제1장 총 칙</h4>
    <div class="item"><span class="font-weight-bolder">제1조(명칭)</span> 본회를 서울대학교 보건대학원 식품 및 외식산업 보건 최고경영자과정 총동문회라 칭한다.</div>
    <div class="item"><span class="font-weight-bolder">제2조(사무소)</span> 본회는 서울특별시에 사무소를 두고 광역시, 각 도 및 해외에 지회를 둘 수 있다.</div>
    <div class="item"><span class="font-weight-bolder">제3조(목적)</span> 본회는 회원 상호간의 우의 증진과 친목을 도모하고 본회와 모교 및 한국 외식산업 발전에 이바지할 것을 그 목적으로 한다.</div>
    <div class="item"><span class="font-weight-bolder">제4조(사업)</span> 본회는 그 목적을 달성하기 위하여 다음과 같은 사업을 수행한다.
      <ul class="lst">
        <li>1. 회원 상호간의 친목을 도모하기 위한 각종 행사</li>
        <li>2. 회원이 필요로 하는 새로운 경영 정보 및 지식 습득을 위한 연구 발표회 및 학술강의</li>
        <li>3. 도서 및 간행물 출간</li>
        <li>4. 모교 발전에 관한 사항</li>
        <li>5. 기타 본회 목적 달성에 필요한 사업</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제5조(사업년도)</span> 본회의 사업년도는 매년 1月 1日부터 동년 12月 31日까지로 한다.</div>
  </div>
  <div class="step">
    <h4>제2장 회 원</h4>
    <div class="item"><span class="font-weight-bolder">제6조(회원의 종류)</span> 본회는 정회원과 명예회원으로 구성한다.
      <ul class="lst">
        <li>1. 정회원은 본 과정을 수료한 자로 한다.</li>
        <li>2. 명예회원은 서울대학교 보건대학교 교직원으로 구성한다. 단, 본 대학원 및 한국 외식산업 발전에 지대한 공로자는 이사회의 의결을 거쳐 총회의 승인을 얻어 명예회원이 될 수 있다.</li>
        <li>3. 명예회원도 본회의 제반 사항에 대하여 총회에 제의할 수 있으나 정회원과 동등한 권리, 대우를 갖지 아니한다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제7조(회원의 권리)</span> 본회의 회원은 본회의 운영에 참여할 권리를 갖는다.</div>
    <div class="item"><span class="font-weight-bolder">제8조(회원의 의무)</span>
      <ul class="lst">
        <li>1. 본회의 정회원은 선거권, 피선거권과 의결권을 갖는다.</li>
        <li>2. 본회의 정회원은 본회의 유지 발전을 위하여 회비를 납부할 의미와 회칙 및 의결 사항을 준수하고 각종 모임에 출석할 의무가 있다.</li>
        <li>3. 본회는 선 예산 책정 후 차기사업을 진행하기 때문, 당 해년 정기총회 개최일로부터 50일 이내, 회비를 납부할 의무가 있다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제9조(회원 탈퇴)</span> 본회를 탈퇴하고자 하는 회원은 퇴회보고서를 제출하여 이사회의 수리를 받아야 한다.</div>
    <div class="item"><span class="font-weight-bolder">제10조(제명)</span> 본회의 회원은 다음 각 항에 해당될 때 총회의 의결로서 경고 제명할 수 있다.
      <ul class="lst">
        <li>1. 본회의 명예를 훼손할 때</li>
        <li>2. 본회의 질서를 문란케 하거나 회비납부의 의무를 이행하지 아니할 때</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제11조(포상과 징계)</span>
      <ul class="lst">
        <li>1. 본회의 발전에 현저한 공로가 있는 자는 포상한다.</li>
        <li>2. 포상 및 징계에 관한 사항은 별도 규정으로 정한다.</li>
      </ul>
    </div>
  </div>
  <div class="step">
    <h4>제3장 총 회</h4>
    <div class="item"><span class="font-weight-bolder">제12조(총회의 구성)</span> 본회의 정회원으로 구성되며 의결기관이다.</div>
    <div class="item"><span class="font-weight-bolder">제13조(총회의 종류)</span> 총회는 정기총회와 임시총회로 구분한다.</div>
    <div class="item"><span class="font-weight-bolder">제14조(총회의 집합)</span>
      <ul class="lst">
        <li>1. 정기총회는 매년 1月, 2月 중에 소집한다.</li>
        <li>2. 임시총회는 매년 11月 중에 소집한다.</li>
        <li>3. 임시총회는 다음 항에 해당될 때 회장이 소집한다.
          <ul class="lst">
            <li>1)회장이 필요하다고 인정할 때</li>
            <li>2)이사회가 소집을 요구할 때</li>
            <li>3)정회원 3분의 1 이상이 요구할 때</li>
          </ul>
        </li>
        <li>4. 전항 2), 3)로의 총회 소집요청을 받은 15일 내에 절차를 밟아 총회를 소집해야 한다.</li>
        <li>5. 전항에 정하여진 이외의 감사는 민법 제67조 제4호의 규정에 입각하여 총회를 소집할 수 있다.</li>
        <li>6. 총회를 소집함에는 회의의 목적이 되는 사항과 일시 및 장소를 서면으로 기록하여 총회 개최 7일 전까지 통지하여야 한다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제15조(총회의 의장)</span> 총회의 의장은 회장이 되며 회장 유고시 수석부회장, 부회장 순으로 그 직무를 대행한다.</div>
    <div class="item"><span class="font-weight-bolder">제16조(총회의 의결)</span> 총회는 출석 표결권수의 과반수 찬성으로 의결한다.</div>
    <div class="item"><span class="font-weight-bolder">제17조(표결권)</span> 前 제8조의 의무를 성실히 수행하지 않은 회원은 일체의 권리를 행사할 수 없다.</div>
    <div class="item"><span class="font-weight-bolder">제18조(총회의 의결사항)</span>
      <ul class="lst">
        <li>1. 다음 각 호의 사항은 총회의 의결을 밟아야 한다.
          <ul class="lst">
            <li>①회칙제정 및 개정</li>
            <li>②현 규정의 제정 및 개정</li>
            <li>③사업계획 및 예산승인</li>
            <li>④사업보고 및 결산승인</li>
            <li>⑤선거직 임원의 선임과 해임</li>
            <li>⑥임명직 임원 승인</li>
            <li>⑦본회의 법적 지위 변경 또는 해산 및 재산 처분방법 결정</li>
            <li>⑧이사회가 필요하다고 인정하는 사항</li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제19조(총회의 특별의결)</span> 다음 각 항은 총회에서 출석 표결권수의 2/3 이상 찬성으로 한다.
      <ul class="lst">
      <li>1. 회칙의 제정 및 개정</li>
      <li>2. 본회 해산과 해산시 잔여 처분방법 결정</li>
      <li>3. 선거직 임원의 해임</li>
      </ul>
    </div>
  </div>
  <div class="step">
    <h4>제4장 임 원</h4>
    <div class="item"><span class="font-weight-bolder">제20조(임원의 구성)</span> 본회는 다음의 임원을 둔다.
    <ul class="lst">
        <li>1. 회장 1인</li>
        <li>2. 명예회장 1인</li>
        <li>3. 수석 부회장 20인</li>
        <li>4. 감사 1인</li>
        <li>5. 재무위원 2인</li>
        <li>6. 홍보위원 2인</li>
        <li>7. 기획위원 2인 (전 사무총장은 당연직)</li>
        <li>8. 부회장(각 기별 회장은 당연직)</li>
        <li>9. 사무총장 1인(상조위원 겸직)</li>
        <li>10. 사무차장 5인</li>
        <li>11. 일반이사(각 기별 추천자)</li>
        <li>12.동아리회장(골프, 등산)</li>
        <li>13. 고문(전임총동문회장은 당연직)</li>
        <li>14. 자문위원(전임 사무총장은 당연직이며 총동문회 회장단 업무를 자문 지원한다. 외식사업의 발전에 현저한 공이 많은 사회의 원로나 동문 중에서도 유능한 회원을 추대한다).
        ＊기존의 위원 및 이사직을 조직의 효울성을 극대화하기 위해 슬림화 한다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제21조(임원의 선출과 해임)</span>
      <ul class="lst">
        <li>1. 회장, 수석부회장, 감사는 선거직 임원으로서 총회에서 선출⋅해임한다.<br/>
        단, 총회소집이 부득이한 경우 이사회가 이를 대체할 수 있으며, 이사회에서 임원을 선출한 경우에는 총회의 인준을 받아야 한다.</li>
        <li>2. 사무총장, 실행이사는 임명직 임원으로서 회장이 임명하여 총회의 승인을 받아야 한다.</li>
        <li>3. 자문위원은 회장 또는 3인 이상의 이사 추천으로 이사회 의결로 추대된다.</li>
        <li>4. 임무를 해태한 임명직 임원은 회장이 이사회의 동의를 얻어 해임한다.</li>
        <li>5. 임무를 기수별 의무금을 완납한 자로서 기 회장의 추천을 받는다.</li>
        <li>6.사무차장은 회장이 선임하며 총회에 승인을 받아야 한다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제22조(임원의 임기)</span>
      <ul class="lst">
        <li>1.임원의 임기는 제5조 사업년도와 동일하게 1년으로 하되 연임할 수 있다.</li>
        <li>2. 증원 또는 보임된 임원의 임기는 전항의 규정과 동일하다.</li>
      </ul>
    </div>

    <div class="item"><span class="font-weight-bolder">제23조(임원의 임무)</span>
      <ul class="lst">
        <li>1. 회장：본회를 대표하는 최고책임자로서 본회를 대표해서 회무를 총괄한다.</li>
        <li>2. 명예회장：회장의 자문에 응하고 선거관리위원회에 관한 제반 업무를 관장한다.</li>
        <li>3. 수석부회장：회장을 보좌하며 회장 유고시 그 직무를 대행한다.</li>
        <li>4. 부회장：회장을 보좌하며 일부 회무를 담당한다.</li>
        <li>5. 감사：본회의 업무 및 재산 현황을 감사하고 민법 제67조에 규정한 직무를 수행하여 총회에 감사보고 해야 한다.</li>
        <li>6. 사무총장：회장단을 보조하며 각종 사업을 협력하여야 할 의무를 갖는다.</li>
        <li>7. 기획위원：회장을 보좌하고 기획업무 및 중점사업 등 계획을 담당한다.</li>
        <li>8. 재무위원：본회의 재정문제에 관하여 회장을 보좌하며 모든 재정 수급 업무를 관장하고 회계상의 책임을 진다.</li>
        <li>9. 홍보위원：본회의 대내외적 홍보관계 업무를 담당하고 자료수집 및 정보 제공을 한다.</li>
        <li>10. 사무차장：회장단을 보좌하고, 사무총장을 지원한다.</li>
        <li>11. 일반이사：본회 발전에 필요한 업무를 담당하며 이사회에 적극 참여할 책임과 의무를 가진다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제24조(임원의 직무)</span> 본회 임원은 맡은바 직무를 성실히 수행하여야 하며 그렇지 않을 경우 회장이 이사회의 의결을 거쳐 그 직무를 정지시킬 수 있다.
      <ul class="lst">
        <li>1. 본회의 임원은 회칙 및 제 규정을 준수하고 본회의 목적 달성에 필요한 사업을 협력하여야 할 의무를 갖는다.</li>
        <li>2. 본회 임원은 당해 연도 정기총회 개최일로부터 50일 이내에 제반 의무금을 기일 내에 납부하여야 한다.</li>
        <li>3. 본회 임원자격은 총동문회비 및 전년도 임원회비 완납자로 한다. 미납자는 당해 연도 임원 및 총동문회 활동(총회⋅이사회⋅동문행사⋅동아리 활동 등) 모든 자격을 상실한다.<br/>
        ＊미납회비 완납과 동시 자격이 복원된다.</li>
      </ul>
    </div>
  </div>
  <div class="step">
    <h4>제5장 이 사 회</h4>
    <div class="item"><span class="font-weight-bolder">제25조(이사회의 구성)</span> 본 규정은 2019년도 4월 1일부터 시행한다.
      <ul class="lst">
        <li>1. 본회 이사회는 다음과 같은 임원으로 구성한다.
          <ul class="list-inline">
            <li class="list-inline-item">①회장</li>
            <li class="list-inline-item">②명예회장</li>
            <li class="list-inline-item">③수석부회장</li>
            <li class="list-inline-item">④부회장</li>
            <li class="list-inline-item">⑤각 위원</li>
            <li class="list-inline-item">⑥재무</li>
            <li class="list-inline-item">⑦감사</li>
            <li class="list-inline-item">⑧사무총장</li>
            <li class="list-inline-item">⑨사무차장</li>
            <li class="list-inline-item">⑩이사</li>
          </ul>
        </li>
        <li>2. 감사는 이사회에 출석하여 의견을 발표할 수 있다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제26조(이사회 소집)</span> 회장은 다음 각 항에 해당될 때 이사회를 소집하며 의장이 된다.
      <ul class="lst">
        <li>1. 회장이 필요하다고 인정할 때</li>
        <li>2. 이사회 구성원 과반수가 회의의 목적되는 사항을 서면으로 기록하여 이사회 소집을 요구할 때</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제27조(이사회 의결)</span>
      <ul class="lst">
        <li>1.이사회는 그 구성원 과반수 출석으로 성립하고 출석 구성원 과반수 찬성으로 의결한다.</li>
        <li>2.총회의 특별 의결 사항을 요하는 사항을 의결할 때는 출석 구성원 2/3 이상 찬성으로 의결한다.</li>
      </ul>
    </div>

    <div class="item"><span class="font-weight-bolder">제28조(의결사항)</span>
      <ul class="lst">
        <li>1. 이사회는 다음 각 호의 사항을 심의 의결한다.
          <ul class="lst">
            <li>①총회에 제출한 의안</li>
            <li>②총회로부터 위임받은 사항</li>
            <li>③회칙 운영에 필요한 규정과 세칙, 내규의 제정 및 개정</li>
            <li>④기타 본회의 업무 집행에 필요한 사항</li>
            <li>⑤총회소집에 부득이한 경우 임원의 선출</li>
          </ul>
        </li>
        <li>2.이사회는 회의 소집 통지서에 기재된 안건에 한해서 심의할 수 있으나 출석이사 2/3 이상이 필요하다고 채택한 안건은 심의할 수 있다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제29조(이사회 회의록)</span> 이사회의 의사에 관하여 의사록을 작성하여 회장 및 이사 2명의 서명을 받아 사무국에 비치하여야 한다.</div>
    <div class="item"><span class="font-weight-bolder">제30조(이사회 회의 종류 구성)(참석범위)</span>
      <ul class="lst">
        <li>1.회장단 회의：회장, 수석부회장, 각 위원, 부회장, 감사</li>
        <li>2.실행임원 회의：회장단, 각 운영위원, 이사</li>
        <li>3.실행이사회：회장, 이사</li>
      </ul>
    </div>
  </div>
  <div class="step">
    <h4>제6장 재 정</h4>
    <div class="item"><span class="font-weight-bolder">제30조(회계년도)</span> 본회의 회계년도는 당해 년도 1月 1日부터 12月 31日까지로 한다.</div>
    <div class="item"><span class="font-weight-bolder">제31조(수지)</span> 본회의 자산은 회비, 임원분담금, 특별회비, 찬조금 및 기타 수입금으로 한다.</div>
    <div class="item"><span class="font-weight-bolder">제32조(재정관리)</span> 본회의 예산은 임원 회의와 이사회를 거쳐 총회의 승인을 받아야 하며 결산은 감사를 거쳐 총회에 보고하여야 한다.</div>
  </div>

  <h3 class="rule-title">경 조 규 정</h3>
  <div class="step">
    <div class="item"><span class="font-weight-bolder">제1조(목적)</span> 본 규정은 동문 상호간의 친목을 도모하고 상부상조하는 데 목적이 있다.</div>
    <div class="item"><span class="font-weight-bolder">제2조(경조)</span> 본 회에서 회원 중 길흉사가 생겼을 때는 다음의 별표에 의하여 경조의를 표한다.</div>
    <div class="item"><span class="font-weight-bolder">제3조(통보)</span> 회원은 혼례, 상례는 필히 동문회 사무실로 통지할 것이나, 회갑, 대소상은 본인의 초청에 의한다.</div>
    <div class="item"><span class="font-weight-bolder">제4조(경조의 표)</span> 본 규정 이외에도 회장단에서 필요하다고 인정할 때는 통상 관례에 의하여 경조를 표할 수 있다.
      <p>※ 별표</p>
      <p>화환 또는 조화, 현금으로 한다.</p>
    </div>
    <div class="item"><span class="font-weight-bolder">제5조(시행)</span> 본 규정은 2019年 2月 11日부터 시행한다.</div>
  </div>

  <h3 class="rule-title">상 벌 규 정</h3>
  <div class="step">
    <div class="item"><span class="font-weight-bolder">제1조(목적)</span> 본 규정은 정관에 정하는 바에 의하여 본회 발전에 현저한 공로가 있는 동문을 포상하고 정관에 위배된 동문은 징벌함을 목적으로 한다.</div>
    <div class="item"><span class="font-weight-bolder">제2조(표창)</span> 본회활동에 현저한 공이 있는 자는 감사패 또는 공로패를 수여한다.
      <ul class="lst">
      <li>1. 본회 활동에 헌신적으로 봉사한 자와 회무활동에 모범이 되거나 특별한 공로가 있는 자는 총동문회장 명의로 감사패를 수여한다.</li>
        <li>2. 위 1항의 조건을 총족하고 학교발전에 현저한 공이 있는 자는 총동문회장과 보건대학원 원장 공동명의로 공로패를 수여한다.</li>
        <li>3. 자랑스러운 서울대인상
          <ul class="lst">
            <li>가. (목적)본 규정은 본회 발전에 헌신적 봉사한 전임총동문회장과 사무총장에게 전 동문의 뜻을 담아 포상하고 격려함을 그 목적으로 한다.</li>
            <li>나. (포상)상패와 함께 부상으로 총동문회장 금10돈, 사무총장 금7돈으로 한다.</li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제3조(심사)</span> 이사회가 심사하여 이사회의 의결로 수상자를 선정한다.</div>
    <div class="item"><span class="font-weight-bolder">제4조(시기)</span>
      <ul class="lst">
        <li>1. 감사패, 공로패 표창은 매년 송년회에서 수여한다.</li>
        <li>2. 자랑스러운 서울대인상은 매년 정기총회에서 포상한다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제5조(경고 및 제명)</span> 정관 제10조에 의한다.</div>
    <div class="item"><span class="font-weight-bolder">제6조(시행)</span> 본 규정은 2009년도 1월 13일부터 재개정하여 시행한다.</div>
  </div>

  <h3 class="rule-title">감 사 규 정</h3>
  <div class="step">
    <div class="item"><span class="font-weight-bolder">제1조(목적)</span> 본회의 업무⋅진행을 감사하며 합리적이고 능률적인 처리의 정비를 도모함으로써 업무 진행의 완벽을 기함에 그 목적을 두고 있다.</div>
    <div class="item"><span class="font-weight-bolder">제2조(감사의 종류)</span>
      <ul class="lst">
        <li>1. 감사는 정기감사와 특별감사의 2종으로 한다.</li>
        <li>2. 정기감사는 회무 전반에 대하여 실시한다.</li>
        <li>3. 특별감사는 특별 부문의 회무 및 회계에 대하여 필요에 따라 실시한다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제3조(감사권)</span>
      <ul class="lst">
        <li>1. 정기감사는 본회의 회무 및 회계에 대하여 감사한다.</li>
        <li>2.특별감사는 실시함에 감사 부문과 대상에 대하여 회장에게 사전 통보하여 승인을 득하여야 한다.(단, 회장은 정당한 이유없이 감사를 거부할 수 없다.)</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제4조(결과보고)</span> 감사를 완료한 감사는 지체없이 그 결과를 서면으로 작성하여 회장을 경유하여 총회에 보고하여야 한다.</div>
    <div class="item"><span class="font-weight-bolder">제5조(감사기간)</span> 정기감사는 매년 7월과 12월 중에 실시한다.</div>
    <div class="item"><span class="font-weight-bolder">제6조(감사성의 주의)</span>
      <ul class="lst">
        <li>1. 감사를 할 때는 감사를 맡은 임원과 각 기구 및 사무국의 정상적인 활동과 업무를 저해할 수 없다.</li>
        <li>2. 감사반원은 감사로 인하여 지특한 사실을 정당한 사유없이 누설하지 못한다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제7조(감사의 협조)</span>
      <ul class="lst">
        <li>1. 피감사자는 감사반이 요구하는 서류의 제출과 그 질문에 대하여 성실한 협조와 답변을 하여야 한다.</li>
      </ul>
    </div>
    <p>부 칙</p>
    <div class="item"><span class="font-weight-bolder">제1조(시행)</span> 본 규정은 1998年 2月 11日부터 시행한다.</div>
  </div>

  <h3 class="rule-title">운영위원회 규정</h3>
  <div class="step">
    <h4>제1장 총 칙</h4>
    <div class="item"><span class="font-weight-bolder">제1조(목적)</span> 총동문회 운영에 관한 필요한 사항을 규정하는데 목적이 있다.</div>
    <div class="item"><span class="font-weight-bolder">제2조(구성)</span>
      <ul class="lst">
        <li>1. 운영위원회 위원장 1인과 약간명의 위원으로 구성한다.
          <ul class="lst">
            <li>①위원은 당해연도에 각 기수별 직전 회장과 전임 총동문회 사무총장을 위촉한다.</li>
            <li>②위원장은 당해연도에 총동문회장이 위촉한다.</li>
          </ul>
        </li>
        <li>2.위원장이 유고시에는 위원 중 총동문회장이 위촉하는 위원이 직무를 대행한다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제3조(임무)</span> 위원회는 본회의 전반적인 운영에 관하여 총동문회장이 요청하는 중요한 사상을 심의하여 총동문회장에게 건의한다.</div>
    <div class="item"><span class="font-weight-bolder">제4조(임기)</span> 위원회의 위원장과 위원의 임기는 1년으로 하되 연임할 수 있다.</div>
  </div>

  <h3 class="rule-title">임원 선임에 관한 규정</h3>
  <div class="step">
    <h4>제1장 총 칙</h4>
    <div class="item"><span class="font-weight-bolder">제1조(목적)</span> 본 규정은 서울대학교 보건대학원 식품 및 외식산업 보건 최고경영자과정 총동문회(이하 본회라 칭한다.)의 선거직 임원의 선출과 당연직 임원, 임명직 임원의 피임명 자격에 관한 사항을 규정함을 목적으로 한다.</div>
    <div class="item"><span class="font-weight-bolder">제2조(선거직 임원)</span>전조의 선거직 임원이라 함은 본회의 회장, 수석부회장 및 감사를 말한다.</div>
    <div class="item"><span class="font-weight-bolder">제3조(당연직 임원)</span>당연직 임원은 각 기수별 회장이 총동문회 당연직 부회장이 된다.</div>
    <div class="item"><span class="font-weight-bolder">제4조(임명직 임원)</span>제20조에 정하는 임원 중 선거직 임원과 당연직 임원을 제외한 모든 임원을 말한다.</div>
    <div class="item"><span class="font-weight-bolder">제5조(선거관리 위원회)</span>선거직 임원의 선거에 관한 업무를 관리하기 위하여 선거관리위원회(이하 선관위라 한다)를 둔다.</div>
  </div>
  <div class="step">
    <h4>제2장 선거관리 위원회</h4>
    <div class="item"><span class="font-weight-bolder">제6조(구성)</span>
      <ul class="lst">
        <li>①관리위원회의 회원은 관리위원장을 포함하여 7인 이하(홀수)로 구성한다.</li>
        <li>②관리위원장은 직전회장이 되며 위원은 감사 1인, 사무총장 1인, 이사 2인, 분과위원장 2인으로 구성한다.</li>
        <li>③사무총장은 선관위의 간사 업무를 맡는다.</li>
        <li>④선관위 임기는 임원의 임기와 동일하다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제7조(업무)</span>
      <ul class="lst">
        <li>①선거관리위원장은 선관위를 대표하여 선관위의 회무를 총괄하고, 총회 및 이사회에 출석하여 선거에 관한 제반업무를 보고하고 의견을 구술해야 한다.</li>
        <li>②선관위 위원장 유고시 관리위원 중에서 회장이 지명하여 이사회에 승인 받은 위원을 선거관리 위원장으로 한다.</li>
        <li>③관리위원의 결원이 있을 때에는 임원 중에서 회장이 지명하여 이사회 승인을 득하여야 한다.</li>
        <li>④선관위 위원은 위원장을 보필하고 공정히 선거업무를 하여야 한다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제8조(의결)</span>선관위 의결은 재적위원 과반수 참석과 출석위원 과반수 찬성으로 구성한다. 관리위원장은 의결권이 없으며 부동수인 경우 그 결정권을 가진다.</div>
    <div class="item"><span class="font-weight-bolder">제9조(임무)</span>선관위는 다음의 의무를 가진다.
      <ul class="lst">
        <li>①입후보자 자격심사</li>
        <li>②선거인명단 작성과 확안</li>
        <li>③총 투표권수의 확정</li>
        <li>④입후보자 신청서 양식 결정</li>
        <li>⑤선거운동 방법 결정</li>
        <li>⑥선거공보 발행</li>
        <li>⑦투표용지, 양식 결정 및 투표방법 결정</li>
        <li>⑧투⋅개표 관리</li>
        <li>⑨당선자 확정보고</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제10조(임무종료)</span> 선거 사무처리가 종료되는 즉시 회장에게 선거관리 결과보고서를 제출하여야 한다. 단, 투표용지는 당선자 확정 발표 후 즉시 봉합하여 10일 경과 후 소각한다.</div>
  </div>
  <div class="step">
    <h4>제3장 선거권 및 선거인 명부</h4>
    <div class="item"><span class="font-weight-bolder">제11조(선거권)</span> 본회에 의무금을 완납한 총동문회 임원(운영위원, 자문위원, 각 기별회장, 각 기별 실행이사 1인)에 한하여 당해연도 선거직 임원 선출권을 가진다.</div>
    <div class="item"><span class="font-weight-bolder">제12조(선거인 명부)</span>
      <ul class="lst">
        <li>①의무금을 완납한 임원에 한하여 명부를 소정기일까지 작성 선거권을 확정한다.</li>
        <li>②전항의 명부에 이의가 있는 임원은 소정기일까지 선관위에 이의를 제기한다.</li>
      </ul>
    </div>
  </div>
  <div class="step">
    <h4>제4장 피선거권 및 피임자격</h4>
    <div class="item"><span class="font-weight-bolder">제13조(입후보 자격)</span> 선거직 임원의 피선거권은 다음과 같다.
      <ul class="lst">
        <li>①선거권 임원 입후보자는 서울대 보건대학원 식품 및 외식산업 보건 최고 경영자 과정을 수료한 자로서 동문회비를 납부한 자.</li>
        <li>②입후보 등록 마감일 현재 미납되어 있을 경우에는 피선거권 또는 피임자격을 인정하지 아니한다.</li>
        <li>③법률상 공민권 박탈당한 회원은 본회 선거직 또는 임원직 임원이 될 수 없다.</li>
        <li>④본회 선거직 임원 입후보자는 동임직 2회에 한하여 입후보할 수 없다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제14조(입후보 등록)</span>
      <ul class="lst">
        <li>①선거직 임원에 입후보하는 회원은 다음 각 호의 서류를 첨부하여 위원회에 등록하여야 한다.
        <li>②ㄱ.입후보 등록신청서 1통
          <ul class="lst">
            <li>ㄴ.입후보 이력서 1통</li>
            <li>ㄷ.입후보 등록 소신서 1통</li>
            <li>ㄹ.명함판 사진 2매</li>
            <li>ㅁ.주민등록초본 1통</li>
            <li>ㅂ.기타 위원회가 필요하다고 인정되는 사회</li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제15조(입후보자 공석시 추천)</span> 본 규정의 선거운동이라 함은 당락을 위한 행위를 말한다.
      <ul class="lst">
        <li>①입후보 등록 마감일까지 입후보자가 없거나 입후보등록 마감 후 입후보자 자격박탈 등의 사유로 공석이 되었을 때에는 본회의 이사회에서 입후보자를 추천할 수 있다.</li>
        <li>②이사회에 추천을 받은 입후보자는 추천 통보 접수일부터 3일 이내 전 14조에 정하는 서류를 선거관리위원회에 제출하여야 한다.</li>
      </ul>
    </div>
  </div>
  <div class="step">
    <h4>제5장 선거운동</h4>
    <div class="item"><span class="font-weight-bolder">제16조(선거운동의 정의)</span> 본 규정의 선거운동이라 함은 당락을 위한 행위를 말한다.</div>
    <div class="item"><span class="font-weight-bolder">제17조(선거운동 기간)</span> 위원회가 입후보자 등록 확정 고지를 통지한 당일부터 선거일 전일까지 하며 이 기간 이외의 선거운동은 일절 할 수 없다.</div>
    <div class="item"><span class="font-weight-bolder">제18조(선거운동)</span>
      <ul class="lst">
        <li>①입후보자를 지지하는 회원은 선거 운동 방법을 선관위가 정하는 바에 따른다.</li>
        <li>②입후보자를 지지하는 회원은 목적과 강력에 따라 명예를 존중하고 모범적인 선거운동에 노력하여야 하며 본 규정 및 선관위가 정하는 선거방법 이외의 행위는 일절 할 수 없다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제19조(입후보 자격 박탈)</span>
      <ul class="lst">
        <li>①입후보 등록이 확정된 자가 제17조 및 제18조의 규정을 위반한 사실이 확인되었을 때에는 선관위가 전 제9조 1항에 의거 당해 입후보자의 피선거권을 박탈할 수 있다.</li>
        <li>②입후보 자격 박탈당한 자는 전항의 이사회에 참여하여 해명할 기회를 가진다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제20조(선거운동 할 수 없는 자)</span>
      <ul class="lst">
        <li>①선거관리 위원은 선거운동을 할 수 없다.</li>
        <li>②선관위원이 특정 후보자를 위하여 선거운동을 하였다고 인정되었을 때는 이사회 의결로서 관리위원직을 박탈할 수 있다.</li>
      </ul>
    </div>
  </div>
  <div class="step">
    <h4>제6장 투표와 개표</h4>
    <div class="item"><span class="font-weight-bolder">제21조(투표와 개표)</span>
      <ul class="lst">
        <li>①투표와 개표는 선거총회에서 실시한다.</li>
        <li>②투표는 무기명 비밀투표 한다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제22조(재투표)</span>
      <ul class="lst">
        <li>①투표결과 최다득표가 재석 투표수를 얻지 못하였을 경우에는 차점자와 재투표를 하여야 한다.</li>
        <li>②재투표시에 동점일 경우 연장자 순으로 한다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제23조(참관인)</span> 투표 및 개표에 있어서 각 입후보자는 1명의 참관인을 둘 수 있다. 참관인은 동문으로서 입후보자가 지명하여 선거 총회 개최 전까지 선관위에 보고한 자라야 한다.</div>
  </div>
  <div class="step">
    <h4>제7장 당선자 결정</h4>
    <div class="item"><span class="font-weight-bolder">제24조(당선자 결정)</span> 본회 선거직 임원 입후보자는 11월 임시총회의 투표자 수 과반수를 득표함으로써 다음 회기의 당선자가 되며 이를 총회 때 보고한다.</div>
    <div class="item"><span class="font-weight-bolder">제25조(직접선출)</span> 제24조에서 당선자가 없을 경우에는 본회의 이사회가 추천하는 자를 당선자로 한다.</div>
  </div>
  <div class="step">
    <h4>제8장 일 정</h4>
    <div class="item"><span class="font-weight-bolder">제26조(일정)</span>
      <ul class="lst">
        <li>①선거를 원활히 하기 위하여 다음 각 호와 같이 일정을 정한다.
          <ul class="lst">
            <li>ㄱ.선거관리위원회 구성：선거일 30일 전</li>
            <li>ㄴ.선거인 명부 작성：선거일 25일 전</li>
            <li>ㄷ.선거인 명부 이의 신청：선거일 20일 전</li>
            <li>ㄹ.선거인 명부 확정 통보：선거일 15일 전</li>
            <li>ㅁ.입후보 등록 마감：선거일 10일 전</li>
            <li>ㅂ.입후보 등록 확정 통보：등록마감 2일 이내</li>
            <li>ㅅ.선거 공보 제작 발송：선거일 7일 전까지</li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제27조(위임 규정)</span> 본 규정에 정하여진 이외의 선거직 임원 선거에 관한 사항은 선관위에서 정하는 바에 따른다.</div>
  </div>

  <h3 class="rule-title">[식품 및 외식산업 보건 최고경영자] 대상 규정</h3>
  <div class="step">
    <h4>제1장 총 칙</h4>
    <div class="item"><span class="font-weight-bolder">제1조(목적)</span> 식품 및 외식산업 보건 최고경영자 대상(이하 본상)은 서울대학 보건대학원 식품 및 외식산업 보건 최고경영자 과정 동문으로 학교의 발전과 총동문회 발전에 헌신적으로 이바지하고 성실한 창의로 외식산업의 발전에 귀감적 업적을 이룩한 동문과 동문 업소 직원 중 공개 표창함으로써 외식인으로서 담당하여야 할 긍지와 생명력 있는 임무와 사명에 대하여 깊은 관심을 갖게 하는 외식 산업 발전에 목적이 있다.</div>
    <div class="item"><span class="font-weight-bolder">제2조(대상 공적의 기간)</span> 본상에 대상이 되는 공적은 당해연도 본상 수상 신청마감일까지의 것에 한한다.</div>
    <div class="item"><span class="font-weight-bolder">제3조(이중 수상의 금지)</span> 본상은 동일한 공적에 대하여 이중으로 수상할 수 없다.</div>
    <div class="item"><span class="font-weight-bolder">제4조(집행위원회)</span>
      <ul class="lst">
        <li>①본상의 시상에 제반업무 운영의 효율성을 기하기 위하여 본회내 집행위원회를 둔다.</li>
        <li>②집행위원회는 본회 자문위원회에서 업무를 관장한다.</li>
        <li>③집행위원회는 본상의 운영에 관한 제반업무를 담당하며 본상의 시상이 완료될 때까지 한다.</li>
        <li>④집행위원회 위원장은 본회 직전회장이 되며 집행위원회는 위원장이 소지하고 의장이 된다.</li>
        <li>⑤본회 사무총장은 집행위원회에 간사로서 의장을 보좌한다.</li>
        <li>⑥집행위원회는 전체 위원 과반수 출석으로 성립되며 출석위원 과반수 이상 찬성으로 의결한다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제5조(상의 종류)</span>
      <ul class="lst">
        <li>①본상의 시상부문은 다음과 같다.
          <ul class="lst">
            <li>ㄱ.식품 및 외식산업 최고경영자 대상 1명</li>
            <li>ㄴ.동문 업체 직원 부문</li>
          </ul>
        </li>
        <li>ⓐ명장 1인</li>
        <li>ⓑ친절 1인</li>
        <li>ⓒ장기근속자 1인</li>
        <li>ⓓ메뉴개발 1인</li>
        <li>ⓔ창의 1인</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제6조(수상자 자격)</span> 본상의 수상자는 다음 각 호에 해당하며 그 업적 및 공적에 해당부문에서 탁월하여 귀감적이라고 인증되는 자라야 한다.
      <ul class="lst">
        <li>①동문부문은 본회원으로서 당해연도 중 가장 활발하게 외식산업 부문에 훌륭한 업적을 남기고 본회 발전에 헌신적으로 노력하고 활동한 자.
        <li>②동문 업체 직원부문은 동문 업체에 1년 이상 근무한 자 중 그 업무와 공적이 해당부문에서 탁월하고 귀감적인 자라고 인정되는 자라야 한다.
          <ul class="lst">
            <li>ㄱ.명장부문：장인정신을 갖고 탁월한 업적을 이룩한 자.</li>
            <li>ㄴ.친절：종사원으로서 업무의 능률개선, 친절 서비스에 타의 모범이 되는 자</li>
            <li>ㄷ.장기근속：한 직장에서 성실한 자세로 최장기간 근무한 자, 탁월한 업무를 이룩한 자.</li>
            <li>ㄹ.메뉴개발：다양한 메뉴개발로 국민 건강과 증진 및 식품 연구 개발에 노력한 자.</li>
            <li>ㅁ.창의：뛰어난 기획력으로 제도개선으로 효율성 있게 운영한 자.</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <div class="step">
    <h4>제2장 수상후보자의 양식</h4>
    <div class="item"><span class="font-weight-bolder">제7조(양식서류)</span> 본상의 수상 후보자의 양식 서류는 다음 각 호와 같다.
      <ul class="lst">
        <li>①이력서 1통</li>
        <li>②양식서 1통(양식 1호)</li>
        <li>③공적조소 1통(양식 2호)</li>
        <li>④사진 1매</li>
        <li>⑤심사에 필요하다고 인증되는 업적 및 공적 증명자료</li>
        <li>⑥재직증명서</li>
      </ul>
    </div>
  </div>
  <div class="step">
    <h4>제3장 심 사</h4>
    <div class="item"><span class="font-weight-bolder">제8조(공적심사)</span> 수상자의 결정은 전 제14조에 규정한 양식서류의 공적심사에 의거 심사위원회의 심의와 이사회의 의결을 거쳐 회장이 확정한다.</div>
  </div>
  <div class="step">
    <h4>제4장 시 상</h4>
    <div class="item"><span class="font-weight-bolder">제9조(시상 내용)</span>
      <ul class="lst">
        <li>①본 상의 내용은 각 부분별로 본 회가 제정하는 부상 및 트로피(패)로 하며 부상으로서 대상에는 금 10돈으로 하고 직원 부분에게는 금 5돈으로 정한다.</li>
        <li>②본 상은 연말 송년의 밤에 시상을 한다.</li>
      </ul>
    </div>
  </div>

  <h3 class="rule-title">총동문회 발전기금 규정</h3>
  <div class="step">
    <div class="item"><span class="font-weight-bolder">제1조(목적)</span> 본 규정은 총동문회 발전을 위한 기금을 조성하여 총동문회에 영속적으로 발전을 위하는 사항을 규정함을 목적으로 한다.</div>
    <div class="item"><span class="font-weight-bolder">제2조(위원회 구성)</span> 위원회 구성은 다음과 같이 한다.
      <ul class="lst">
        <li>1.자문위원</li>
        <li>2.사무총장</li>
        <li>3.위원장은 당해연도 직전 회장으로 한다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제3조(위원회의 임무)</span> 위원회는 다음 각 호의 임무를 가진다.
      <ul class="lst">
        <li>1.기금의 관리</li>
        <li>2.위원회의 효율적 운영과 추진방법 연구</li>
        <li>3.기타 발전기금에 관한 제반 업무</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제4조(위원회 소집)</span>
      <ul class="lst">
        <li>1.정기소집은 연2회 위원장이 소집한다.</li>
        <li>2.임시소집은 위원장이 필요하다고 인증할 때 또는 총회 및 이사회 의결하였을 때 위원장이 소집한다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제5조(기금의 확보)</span> 위원회의 기금을 확보하는 방법은 다음과 같다.
      <ul class="lst">
        <li>1.본회 총동문 발전 기금으로 특별 회계 처리된 기금</li>
        <li>2.찬조금</li>
        <li>3.기타 수입금</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제6조(기금의 관리)</span> 위원장 책임하에 재무이사가 담당한다.</div>
    <div class="item"><span class="font-weight-bolder">제7조(기금 증서 및 인장의 보관)</span>
      <ul class="lst">
        <li>1.기금은 금융기관에 보관 관리한다.</li>
        <li>2.기금의 인장은 위원장이 보관하고 증서는 분회 회장이 보관한다.</li>
        <li>3.위의 인수인계는 매년 초 사무실에서 행한다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제8조(위원회의 사업)</span>
      <ul class="lst">
        <li>1.적립하는 것을 원칙으로 한다.</li>
        <li>2.위원회는 총회의 동의를 얻어 기금을 효율성 있게 사업을 추진한다.</li>
        <li>3.기금 확보를 위한 사업을 전개한다.</li>
      </ul>
    </div>
    <div class="item"><span class="font-weight-bolder">제9조(위원회의 임기)</span> 위원회의 임기는 본회 임기와 같다.</div>
    <div class="item"><span class="font-weight-bolder">제10조(회무보고)</span> 위원장은 총회, 이사회에 출석하여 회무 보고 또는 그 운영에 관한 의견을 구술해야 한다.</div>
    <div class="item"><span class="font-weight-bolder">제11조(기록 보존)</span> 위원회는 위원장의 책임하에 기금배진, 기타 관계 서류를 작성하여 사무처에 보존해야 한다.</div>
  </div>


    <!--/ content -->
  </div>
</template>

<script>
  import {
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BImg,
    BLink,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BCard,
    BRow,
    BCol,
    BBadge,
    BCardText,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormTextarea,
    BFormCheckbox,
    BButton,
  } from 'bootstrap-vue';
  import Ripple from 'vue-ripple-directive';
  import { kFormatter } from '@core/utils/filter';

  export default {
    components: {
      BFormInput,
      BMedia,
      BAvatar,
      BMediaAside,
      BMediaBody,
      BLink,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BInputGroup,
      BInputGroupAppend,
      BImg,
      BBadge,
      BCardText,
      BDropdown,
      BForm,
      BDropdownItem,
      BFormTextarea,
      BFormCheckbox,
      BButton,
    },
    data() {
      return {
        userData: JSON.parse(localStorage.getItem('userData')),
      };
    },
  };
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-blog.scss';
  .rule-title {
    text-align:center;
  }
  .step {
    margin-bottom: 34px;
  }
  .step > .item {
    margin-bottom: 10px;
  }
  .lst {
    padding-left: 10px;
  }
  .lst > li {
    list-style-type: none;
  }
</style>
